import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const ServicesPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  disciplines,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <br />
            <div className="columns is-multiline is-desktop">
              {disciplines.map((discipline) => (
                <div
                  key={discipline.name + `discipline`}
                  className={"column is-6-desktop discipline"}
                >
                  <div className="simple-border">
                    <h4 className="has-text-centered">{discipline.name}</h4>
                    <p className="preserve-whitespace">{discipline.blurb}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ServicesPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  disciplines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      blurb: PropTypes.string,
    })
  ),
};

const Service = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ServicesPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        disciplines={post.frontmatter.disciplines}
      />
    </Layout>
  );
};

Service.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Service;

export const pageQuery = graphql`
  query ServiceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        disciplines {
          name
          blurb
        }
      }
    }
  }
`;
